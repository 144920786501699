.page-numbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5em;
    a, span {
        border-radius: 50%;
        background-color: var(--white-100);
        padding: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            background-color: var(--blueberry-40); 
        }
    }

    a {
        transition: 0.2s background-color ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: var(--haze-80);
        }
    }

    span:not(.active) {
        color: lightgrey;
    }

    &.cloud {
        a, span {
            background-color: var(--cloud-40);
        }
    }
}