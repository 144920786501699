.aligned-image {
    display: flex;
    column-gap: 10px;
    margin-top: 15px;

    &.right {
        flex-direction: row-reverse;
    }

    &.background {
        padding: 15px;
    }

    &.background, .image.background {

        &.green {
            background: var(--green-100);
        }

        &.blue {
            background: var(--blue-100);
            color: #fff;
        }

        &.grey {
            background: var(--grey-100);
        }
    }

    &.clickable {
        cursor: pointer;
    }

    .image {
        align-self: center;
        
        &.adjusted {
            min-width: 100px;
        }

        &.background {
            padding: 5px;
            border-radius: 50%;
            width: 25px;
            height: 25px;
        }
        
        img {
            display: block;
            margin: 0 auto;
        }
    }

    .text {
        align-self: center;

        &.text-left {
            text-align: left;
        }

        &.text-center {
            text-align: center;
        }

        &.text-right {
            text-align: right;
        }

        p {
            padding-top: 0;
        }
    }
}

.wrapped-aligned-image {
    height: 100%;

    .aligned-image {
        display: grid;
        height: 100%;
        grid-template-rows: auto 1fr;

        .text {
            align-self: center;
        }

        &.bottom {
            .image {
                grid-row: 2;
                align-self: flex-end;
            }

            .text {
                align-self: auto;
            }
        }

        &.top .text p:first-child {
            margin-top: 20px;
        }
    }
}