.badge {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    row-gap: 0;

    .top {
        &, & .image {
            width: 100%;
        }

        .image {
            box-shadow: 0px 3px 20px 5px rgba(0, 0, 0, 0.05);
            padding-top: 56.25%;
            background-size: cover;
            -webkit-border-radius: 10px 10px 0 0;
            -moz-border-radius: 10px 10px 0 0;
            border-radius: 10px 10px 0 0;
            display: block;
            width: 100%;
            opacity: 0.8;
            filter: alpha(opacity=80);
            zoom: 1;
            transition: 0.2s all ease-in-out;
        }
    }

    &:hover .top .image {
        opacity: 1;
        filter: alpha(opacity=100);
        zoom: 1;
    }

    .bottom {
        background: var(--white-100);
        -webkit-border-radius: 0 0 10px 10px;
        -moz-border-radius: 0 0 10px 10px;
        border-radius: 0 0 10px 10px;
        padding: 30px 40px 40px 40px;
        grid-row: 2 / 3;

        .links * {
            display: inline-block;
        }

        h1, h2, h3, h4, h5, h6 {
            &, & a {
                font-size: var(--fontSizeHeader3);   
                text-decoration: none;
                font-weight: var(--fontWeightNormal);
                padding-top: 16px;
                line-height: 130%;
            }
        }

        :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p) a {
            &, &:link, &:hover, &:visited {
                text-decoration: none;
            }
        }

        p {
            padding-top: 16px;
            word-break: break-word;

            a, a:link, a:hover, a:visited {
                text-decoration: none;
            }
        }
    }
}