.table {
    width: 100%;

    &.table-bmi {
        tr {
            td {
                padding: 5px 0;
            }
        }

        &:nth-of-type(2) {
            tr:nth-of-type(1) td:first-child,
            tr:nth-of-type(2) td:first-child {
                font-weight: bold;
            }
        }

        &:nth-of-type(1) tr td:first-child,
        &:nth-of-type(2) tr:first-child td:first-child,
        &:nth-of-type(2) tr:nth-child(2) td:nth-child(2),
        &:nth-of-type(2) tr:nth-child(3) td:nth-child(1) {
            text-align: center;
        }

        &:nth-of-type(1) tr:first-child td:first-child,
        &:nth-of-type(2) tr:nth-of-type(2) td:nth-child(2) {
            border-bottom: 1px solid #000;
        }
    }
}