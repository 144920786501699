.flex {
    display: flex;
}

.flex-group {
    gap: 8px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-group .btn {
    background-color: #41536F;
    color: #fff;
    border-width: 0;

    &:hover {
        background-color: #5E7598;
    }

    &:active {
        background-color: #8398B8;
    }

    .gn.gn-icons.arrow-link {
        background-image: url('../img/icons/arrow-link-white.svg');
    }
}