.priceWrapper {
    padding-top: 48px;
    padding-bottom: 48px;
}

.price {
    border: 2px solid #41536F;
    border-radius: 12px;
    min-height: 286px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
        h3 {
            font-size: 28px;
            padding-top: 42px;
        }

        p {
            padding-top: 16px;
            font-size: 24px;
        }

        h3, p {
            text-align: center;
        }

        .total_price {
            font-size: 18px;
            padding-top: 8px;
        }
    }

    button {
        width: 100%;
        background-color: #41536F;
        border: 0;
        color: white;
        font-size: 24px;
        padding: 20px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;


        &:hover {
            background-color: #5E7598;
        }

        &:active {
            background-color: #8398B8;
        }

        span + i {
            margin-left: 16px;
        }
    }
}