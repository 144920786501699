.grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-auto-rows: minmax(min-content, max-content);
    grid-column-gap: var(--grid-gap);
    row-gap: var(--grid-gap);
    box-sizing: border-box;

    &.col-1, .col-1 {
        grid-column: span 1;
    }

    &.col-2, .col-2 {
        grid-column: span 2;
    }

    &.col-3, .col-3 {
        grid-column: span 3;
    }

    &.col-4, .col-4 {
        grid-column: span 4;
    }

    &.col-5, .col-5 {
        grid-column: span 5;
    }

    &.col-6, .col-6 {
        grid-column: span 6;
    }

    &.col-7, .col-7 {
        grid-column: span 7;
    }

    &.col-8, .col-8 {
        grid-column: span 8;
    }

    &.col-9, .col-9 {
        grid-column: span 9;
    }

    &.col-10, .col-10 {
        grid-column: span 10;
    }

    &.col-11, .col-11 {
        grid-column: span 11;
    }

    &.col-12, .col-12 {
        grid-column: span 12;
    }

    &.auto-template-rows {
        grid-template-rows: auto 1fr;
    }

    &.padding-bottom:last-of-type {
        padding-bottom: 30px;
    }

    &.padding-top {
        padding-top: 60px;
    }
}

.container.bloated .grid {
    padding: 0 $containerPaddingBloated;
}

@media(max-width: $resolutionLg) {
    .grid {
        &.col-md-1, .col-md-1 {
            grid-column: span 1;
        }
        &.col-md-2, .col-md-2 {
            grid-column: span 2;
        }
        &.col-md-3, .col-md-3 {
            grid-column: span 3;
        }
        &.col-md-4, .col-md-4 {
            grid-column: span 4;
        }
        &.col-md-5, .col-md-5 {
            grid-column: span 5;
        }
        &.col-md-6, .col-md-6 {
            grid-column: span 6;
        }
        &.col-md-7, .col-md-7 {
            grid-column: span 7;
        }
        &.col-md-8, .col-md-8 {
            grid-column: span 8;
        }
        &.col-md-9, .col-md-9 {
            grid-column: span 9;
        }
        &.col-md-10, .col-md-10 {
            grid-column: span 10;
        }
        &.col-md-11, .col-md-11 {
            grid-column: span 11;
        }
        &.col-md-12, .col-md-12 {
            grid-column: span 12;
        }
    }
}

@media(max-width: $desktopResolutionMinWidth) {
    .grid {
        grid-column-gap: 0;
        &.col-sm-1, .col-sm-1 {
            grid-column: span 1;
        }
        &.col-sm-2, .col-sm-2 {
            grid-column: span 2;
        }
        &.col-sm-3, .col-sm-3 {
            grid-column: span 3;
        }
        &.col-sm-4, .col-sm-4 {
            grid-column: span 4;
        }
        &.col-sm-5, .col-sm-5 {
            grid-column: span 5;
        }
        &.col-sm-6, .col-sm-6 {
            grid-column: span 6;
        }
        &.col-sm-7, .col-sm-7 {
            grid-column: span 7;
        }
        &.col-sm-8, .col-sm-8 {
            grid-column: span 8;
        }
        &.col-sm-9, .col-sm-9 {
            grid-column: span 9;
        }
        &.col-sm-10, .col-sm-10 {
            grid-column: span 10;
        }
        &.col-sm-11, .col-sm-11 {
            grid-column: span 11;
        }
        &.col-sm-12, .col-sm-12 {
            grid-column: span 12;
        }
    }
}

@media(max-width: $tabletResolutionMinWidth) {
    .grid {
        grid-column-gap: 0;
        &.col-xs-1, .col-xs-1 {
            grid-column: span 1;
        }
        &.col-xs-2, .col-xs-2 {
            grid-column: span 2;
        }
        &.col-xs-3, .col-xs-3 {
            grid-column: span 3;
        }
        &.col-xs-4, .col-xs-4 {
            grid-column: span 4;
        }
        &.col-xs-5, .col-xs-5 {
            grid-column: span 5;
        }
        &.col-xs-6, .col-xs-6 {
            grid-column: span 6;
        }
        &.col-xs-7, .col-xs-7 {
            grid-column: span 7;
        }
        &.col-xs-8, .col-xs-8 {
            grid-column: span 8;
        }
        &.col-xs-9, .col-xs-9 {
            grid-column: span 9;
        }
        &.col-xs-10, .col-xs-10 {
            grid-column: span 10;
        }
        &.col-xs-11, .col-xs-11 {
            grid-column: span 11;
        }
        &.col-xs-12, .col-xs-12 {
            grid-column: span 12;
        }
    }
}