.landingPrices {
    h2, h3, p {
        text-align: center;
        margin: 0 auto;
    }

    h2 {
        max-width: 966px;
        font-size: 40px;
        line-height: 56px;
        padding-bottom: 98px;
    }

    h3 {
        padding-top: 98px;
        max-width: 862px;
        font-size: 36px;
        line-height: 50.4px;
    }

    p {
        padding-top: 98px;
        max-width: 667px;
        font-size: 18px;
        line-height: 25.2px;
    }

    .container.full.featured p {
        padding-top: 0;
        text-align: left;
    }
}

.landingPage .padding-bottom {
    padding-bottom: 96px;
}

.landingPrices,
.landingPage {
    .aligned-image {
        display: flex;
        align-items: center;
        background-color: #41536F;
        border-radius: 12px;
        color: #fff;
        min-height: 180px;

        .image {
            &, img {
                width: 180px;
                min-width: 180px;
                height: 100%;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            img {
                object-fit: cover;
            }
        }

        .icon {
            min-width: 180px;
            width: 180px;
            background-color: #273751;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;

            i {
                font-size: 64px;
                text-align: center;
                color: #839BC0;
            }
        }

        &.top .text {
            strong {
                display: block;
            }

            strong, p {
                padding: 30px 32px 30px 48px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.landingPage .aligned-image.top .text {
    strong {
        padding: 30px 32px 0 48px;
    }
    
    p {
        padding: 8px 32px 30px 48px;
    }
}

@media(max-width: $desktopResolutionMinWidth) {
    .landingPage .aligned-image {
        .icon,
        .image,
        .image img {
            min-width: 95px;
            width: 95px;
        }

        &.top .text {
            strong, p {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}