footer {
    width: 100%;
    background-color: var(--haze-100);
    margin-top: $containerMargin;

    .container {
        width: auto;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(5, auto);
        padding: 74px 70px;
        flex-shrink: 0;
        margin: 0 auto;

        &.text-container {
            max-width: 100%;
            background-color: var(--blueberry-40);
            margin: 0;
            padding: 28px 24px;
            display: flex;
            justify-content: center;
        }

        ul {
            &.el {
                list-style-type: none;
                padding: 0;
                margin: 0;

                &.contact li.logo-gn {
                    margin-top: 11px;
                }
            }
    
            &.advertisement {
                grid-row: 2;
                grid-column: 2 / span 3;
                position: relative;
                top: -50px;
            }
    
            li {
                font-size: var(--fontSizeParagraphSmall);
                line-height: 22px;
                padding-top: 21px;
    
                &:first-of-type {
                    padding-top: 0;
                }
    
                &.logo {
                    cursor: pointer;
                }
    
                a {
                    font-size: var(--fontSizeParagraphMedium);
                    line-height: 24px;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
    
                &.title {
                    &, a {
                        font-size: var(--fontSizeHeader3);
                        font-weight: var(--fontWeightNormal);
                        line-height: 140%;
                    }
                }
            }
        }
    }
}

@media(max-width: $tabletResolutionMinWidth) {
    footer {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-auto-rows: minmax(0, min-content);
        grid-gap: 30px;
        //padding: 40px 25px 60px 25px;

        .container {
            padding-left: 28px;
            padding-right: 28px;
        }

        ul.el {
            grid-column: span 12;

            &.advertisement {
                grid-row: 4;
                top: 0;
            }

            li {
                padding-top: 10px;

                &:first-of-type {
                    padding-top: 0;
                }
                
                &, a {
                    font-size: var(--fontSizeParagraphSmall);
                    line-height: 30px;
                }
            }

            &.contact {
                display: grid;
                
                li {
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    grid-auto-rows: minmax(0, min-content);
                    grid-column: span 12;
                    grid-row: 1;

                    &:nth-of-type(2) {
                        grid-row: 2;
                    }

                    &:nth-of-type(3) {
                        grid-row: 3;
                    }

                    &.logo-gn {
                        grid-row: 1 / 4;
                        justify-self: end;
                        margin-top: 0;
                    }
                }
            }

            &.social, &.terms {
                grid-column: span 6;
                padding-top: 32px;
            }

            &.back-to-top {
                display: none;
            }
        }
    }
}