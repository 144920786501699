.container.post {
    padding-top: 0;
    padding-bottom: 24px;

    h1 {
        line-height: 61px;
    }

    .categories {
        padding-top: 30px;

        a, a:link, a:hover, a:visited {
            font-size: var(--fontSizeParagraphLarge);
            line-height: 27px;
            text-decoration: underline;
            color: var(--brocolli-80);
        }
    }

    .intro {
        font-size: var(--fontSizeParagraphLarge);
        line-height: 27px;
        font-weight: var(--fontWeightNormal);
    }

    h2:not(.section-hero) {
        padding-top: 80px;

        &:first-of-type {
            padding-top: 20px;
        }

        & ~ & {
            padding-top: 40px;
        }
    }

    h3, h4, h5, h6, p, ul, ol {
        padding-top: 30px;
    }

    ul, ol {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-top: 10px;
        padding-inline-start: 20px;
        
        li {
            padding-top: 10px;
        }
    }

    .author {
        margin-top: 24px;
        border-top: 1px solid var(--blueberry-40);
        grid-gap: 20px;
        padding-top: 20px;
        padding-bottom: 48px;
        font-size: var(--fontSizeParagraphSmall);
        line-height: 22px;

        & ~ .author {
            margin-top: 20px;
        }

        .image {
            width: 100%;
            height: 0;
            padding-bottom: 86.7%;
            object-fit: cover;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 10px;
        }

        .details {
            align-self: center;

            .name {
                //font-weight: var(--fontWeightBold);
                font-size: var(--fontSizeHeader3);
            }
    
            .bio {
                padding-top: 12px;
                font-size: var(--fontSizeParagraphSmall);
            }
    
            .name, .bio {
                line-height: 140%;
            }
        }
    }
}