.pill {
    background-color: var(--brocolli-40);
    border-radius: 24px;
    padding: 6px 14px;
    font-size: var(--fontSizeLinkSmall);
    line-height: 17px;
    transition: 0.2s all ease-in-out;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.pill:hover {
    background-color: var(--brocolli-60);
}