.page {
    &, & ~ & {
        padding-top: $containerMarginSecondary;
        padding-bottom: $containerMarginSecondary;
    }

    p {
        font-size: var(--fontSizeParagraphLarge);
        line-height: 27px;
    }

    &.onepage h2, h3, h4, h5, h6, p, ul, ol {
        padding-top: 30px;
    }

    &.onepage {
        figure {
            &, img {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }

    ul, ol {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-top: 10px;
        padding-inline-start: 20px;

        li {
            padding-top: 10px;
        }
    }

    .team {
        padding-top: 24px;

        .employee {
            .image {
                width: 100%;
                height: 0;
                padding-bottom: 86.7%;
                object-fit: cover;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 10px;
            }

            h3 {
                padding-top: 20px;
            }

            p {
                padding-top: 15px;
                font-size: var(--fontSizeParagraphSmall);
                line-height: 22px;
            }
        }
    }
}