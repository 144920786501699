// Reset
// =============================================================================

html, body, p, dl, dt, dd, blockquote, figure, fieldset, legend,
textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

button, input, select, textarea {
  margin: 0;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}
