input {
    width: 100%;
    background-color: var(--white-100);
    border: 1px solid var(--brocolli-40);
}

button {
    background-color: var(--haze-100);
    border: 1px solid var(--brocolli-40);
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &:hover {
        background-color: var(--haze-80);
    }
}

.btn {
    background-color: var(--brocolli-60);
    padding: 12px 32px;
    border: 6px solid var(--brocolli-40);
    border-radius: 100px;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;

    &.btn-white {
        background-color: var(--white-100);

        &:hover {
            background-color: var(--haze-60);
        }
    }

    &.btn-round {
        border: none;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        padding: 0;
        //justify-content: center;

        i {
            margin-left: 23px;
        }
    }

    &.btn-w-icon {
        border-radius: 12px;
        border-width: 0;
        background-color: var(--brocolli-80);
        color: var(--white-100);
        justify-content: space-between;
        padding: 34px 32px;
        height: calc(100% - 34px - 34px);

        .text {
            align-self: flex-start;
            
            h2 {
                font-size: var(--fontSizeHeader3);
                font-weight: var(--fontWeightNormal);
                margin-bottom: 8px;
                line-height: 134%;
            }

            p {
                font-size: var(--fontSizeLinkLarge);
            }
        }

        i.gn.gn-icons.arrow-link {
            background-image: url('../img/icons/arrow-link-white.svg');
        }

        &:hover {
            background-color: var(--brocolli-60);
            color: var(--blueberry-100);

            i.gn.gn-icons.arrow-link {
                background-image: url('../img/icons/arrow-link.svg');
            }
        }
    }

    &.btn-w-body {
        height: auto;
    }

    span, .text {
        flex: 1;
    }
    
    i {
        margin-left: 16px;
    }

    &:hover {
        background-color: var(--brocolli-80);
        border-color: var(--brocolli-60);
        color: var(--white-100);

        i.gn.gn-icons.arrow-link {
            background-image: url('../img/icons/arrow-link-white.svg');
        }
    }
}

input, button {
    padding: 10px 20px;
    border-radius: 10px;
}

.form-group {
    display: flex;

    input {
        //flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input + * {
        display: flex;
        align-items: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;

        i {
            margin-left: 5px;
        }
    }
}