//$maxContainerWidth: 1440px;

// Top margin of containers
$containerMargin: 100px;

// Container margin on pages
$containerMarginSecondary: 60px;

// Container margin on posts
$containerMarginTertiary: 80px;

// Top margin of first container
$containerFirstMargin: 30px;

$containerPadding: 274px;
$containerPaddingBloated: 100px;

// Grid gap
$gridGap: 60px;

$maxContainerWidth: 1376px;
$maxContainerFeaturedImageWidth: 1728px;
$maxContainerFeaturedImageFactor: 2.6470;

:root {
    --grid-gap: 42px;
}