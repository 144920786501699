:root {
    // Weights
    --fontWeightLight: 100;
    --fontWeightNormal: 400;
    --fontWeightSemiBold: 600;
    --fontWeightBold: 700;

    // Sizes
    --fontSizeHero: 36px;
    --fontSizeHeader1: 42px;
    --fontSizeHeader2: 28px;
    --fontSizeHeader3: 20px;
    --fontSizeMobileMenu: 23px;
    --fontSizeParagraphLarge: 20px;
    --fontSizeParagraphMedium: 18px;
    --fontSizeParagraphSmall: 16px;
    --fontSizeLinkLarge: 16px;
    --fontSizeLinkMedium: 14px;
    --fontSizeLinkSmall: 12px;
}

@media(min-width: $desktopResolutionMinWidth) {
    :root {
        // Desktop font sizes
        --fontSizeHero: 40px;
        --fontSizeHeader1: 50px;
        --fontSizeHeader2: 36px;
        --fontSizeHeader3: 22px;
        --fontSizeMobileMenu: 25px;
        --fontSizeParagraphLarge: 22px;
        --fontSizeParagraphMedium: 20px;
        --fontSizeParagraphSmall: 18px;
        --fontSizeLinkLarge: 18px;
        --fontSizeLinkMedium: 16px;
        --fontSizeLinkSmall: 14px;
    }
}