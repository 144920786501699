// Colors go here
:root {
    // White
    --white-100: #fff;

    // Blueberry
    --blueberry-100: #364757;
    --blueberry-80: #5F6C78;
    --blueberry-60: #9BA3AB;
    --blueberry-40: #D7DADD;

    // Brocolli
    --brocolli-100: #2B5048;
    --brocolli-80: #56736D;
    --brocolli-60: #95A7A3;
    --brocolli-40: #D5DCDA;

    // Cloud
    --cloud-100: #B5CBCB;
    --cloud-80: #C4D5D5;
    --cloud-60: #DAE5E5;
    --cloud-40: #F0F5F5;

    // Haze
    --haze-100: #E4E6E7;
    --haze-80: #E9EBEC;
    --haze-60: #F2F2F3;
    --haze-40: #FAFAFA;

    --green-100: #43948b;
    --blue-100: #163671;
    --grey-100: #e2efee;
}