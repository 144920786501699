// Header
header {
    width: 100%;

    nav {
        &.top {
            height: 100px;
            display: flex;
            justify-content: space-between;
            max-width: $maxContainerWidth;
            margin: 0 auto;
            padding: 0 70px;

            ul {
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    line-height: 150%;
                    font-size: var(--fontSizeLinkLarge);
                    padding: 10px 24px;
                    border-radius: 24px;
                    margin-right: 6px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.logo {
                        cursor: pointer;
                        margin-right: auto;
                    }

                    a {
                        text-decoration: none;
                    }

                    &.active {
                        background-color: var(--haze-100);
                    }

                    &:hover {
                        background-color: var(--haze-80);
                    }

                    &.mobile {
                        display: none;
                    }

                    .flex {
                        display: flex;

                        &.align-center {
                            align-self: center;

                            i {
                                margin-left: 5px;
                            }
                        }
                    }

                    &.search-icon {
                        i {
                            padding-right: 12px;

                            &.magnifying-glass {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .expandable {
        &.mobile, &.collapsed {
            display: none;
        }

        &.expandable-bg {
            padding: 20px 40px;
            background: var(--cloud-40);
        }

        .search-group {
            max-width: 768px;
            margin: 0 auto;
        }

        ul.mobile {
            list-style-type: none;
            margin: 0;
            padding: 10px 0 80px 0;
            background: var(--cloud-40);

            li {
                padding: 30px 25px 0 25px;
                
                &, a, a:link, a:hover, a:visited {
                    font-size: var(--fontSizeMobileMenu);
                    line-height: 30px;
                    text-decoration: none;
                }
            }
        }
    }
}

@media(max-width: $largeDesktopResolutionMinWidth) {
    header nav.top {
        padding: 0 35px;
    }
}

@media(max-width: $desktopResolutionMinWidth) {
    header {
        nav.top {
            ul li {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        .expandable {
            &, &.mobile {
                display: block;
            }
            
            &.collapsed {
                display: none;
            }
        }
    }
}

@media(max-width: $tabletResolutionMinWidth) {
    header nav.top {
        padding: 0 25px;

        ul li {
            padding-right: 15px;

            &:last-of-type {
                padding-right: 0;
            }
        }
    }
}

@media print {
    header {
        display: none;
    }
}