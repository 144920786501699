.container {
    width: 100%;
    max-width: $maxContainerWidth;
    padding: $containerFirstMargin 0 $containerMargin 0;
    margin: 0 auto;


    & ~ .container {
        padding-top: $containerMargin;
        padding-bottom: $containerMargin;
    }

    h1, h2, h3, h4, h5, h6 {
        word-break: break-word;

        & + p {
            padding-top: 10px;
        }
    }

    &.cloud {
        background: var(--cloud-40);
    }

    &:not(.bloated) .wrapper {
        max-width: $maxContainerWidth - (28px * 2);
        margin: 0 auto;
        padding-left: 28px;
        padding-right: 28px;
    }

    &.bloated .wrapper {
        padding: 0 $containerPaddingBloated;
    }

    h2.header {
        padding-bottom: 40px;
    }

    a {
        //display: inline-block;
    }

    h1, h2, h3, h4, h5, h6, p {
        display: block;
    }

    &.featured {
        width: 100%;
        padding: 0;

        & ~ .container {
            padding: 0;
        }

        &, .wrapper {
            max-width: $maxContainerFeaturedImageWidth;
        }

        .wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0 auto;
            align-items: center;
            padding-bottom: 56px;
            min-height: 500px;
            padding-right: 0;

            .text {
                max-width: ($maxContainerWidth / 2) - 28px;
                margin: 0 0 0 auto;
                h1 {
                    font-weight: var(--fontWeightNormal);
                    font-size: var(--fontSizeHero);
                    line-height: 140%;
                }

                p {
                    margin-top: 12px;
                    font-size: var(--fontSizeParagraphMedium);
                    line-height: 140%;
                }

                h1, p, .flex {
                    padding-right: 24px;
                }

                h1 {
                    font-weight: var(--fontWeightSemiBold);
                }

                h1 + p {
                    margin-top: 24px;
                }

                .flex {
                    margin-top: 24px;
                }
            }

            &.no-image {
                grid-template-columns: 1fr;
                max-width: $maxContainerWidth;
                min-height: 150px;

                .text {
                    max-width: 100%;
                    width: 100%;
                    text-align: center;
                }
            }
    
            .image {
                position: relative;
                align-self: start;
    
                &, img {
                    height: 100%;
                }
    
                img {
                    position: absolute;
                    //right: (($maxContainerFeaturedImageWidth - $maxContainerWidth) / 2) * -1;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
            }
        }

        &:not(.bloated) {
            padding: 0;
        }

        .featured-image {
            width: 100%;
            height: auto;
            min-height: 500px;
            max-width: $maxContainerFeaturedImageWidth;
            max-height: calc(#{$maxContainerFeaturedImageWidth} / #{$maxContainerFeaturedImageFactor});
            margin: 0 auto;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            background-position: center center;

            &.home-static {
                background-image: url('../img/frontpage-featured-image-3.webp');
            }

            &.pos-top {
                background-position-y: top;
            }

            &.pos-bottom {
                background-position-y: bottom;
            }
        }
    }

    &.bloated.featured {
        .featured-image {
            box-shadow: 0px 3px 20px 5px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
        }

        .wrapper {
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            grid-auto-rows: minmax(0, min-content);
            grid-column-gap: $gridGap;
            box-sizing: border-box;

            .featured-image, h1.hero, h1.hero + div {
                grid-column: span 6;
            }

            .featured-image {
                grid-row: 1 / 3;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
            }

            h1.hero {
                align-self: end;
                font-weight: var(--fontWeightSemiBold);
                grid-row: 1;

                span {
                    display: block;
                    font-size: var(--fontSizeHeader2);
                    line-height: 48px;
                }
            }

            p {
                grid-row: 2;
                align-self: start;
                font-size: var(--fontSizeParagraphMedium);
                line-height: 24px;
            }

            h1.hero, p {
                padding: 20px 25px;
            }

            p {
                padding-top: 30px;
                padding-bottom: 0;

                &:first-of-type {
                    padding-top: 40px;
                }

                &:last-of-type {
                    padding-bottom: 40px;
                }
            }

            .featured-content {
                align-self: center;

                p {
                    padding-top: 50px;
                }
            }
        }
    }

    .is-collapsable {
        .state-collapsed {
            display: none;
        }

        h1, h2 {
            display: block;
            cursor: pointer;
            background: url('../img/icons/chevron-up.svg') no-repeat;
            background-position: right calc(50% + 15px);
            padding: 0 50px 0 0;
        }

        &.is-collapsed {
            h1, h2 {
                background-image: url('../img/icons/chevron-down.svg');
            }
        }

        .read-more {
            text-decoration: underline;
            text-align: center;
            font-size: var(--fontSizeParagraphLarge);
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.centered-container {
    display: flex;
    justify-content: center;
}

@media(max-width: $desktopResolutionMinWidth) {
    .container {
        &:not(.bloated) .wrapper {
            padding: 0 25px;
        }

        &.full, &.bloated {
            &.featured .wrapper {
                grid-column-gap: 0px;
            }
        }

        &.bloated.featured {
            padding: 0;
            .wrapper {
                padding: 0;
                .featured-image, h1.hero, h1.hero + div {
                    grid-column: span 12;
                }
                
                h1.hero {
                    grid-row: 1;
                    font-size: var(--fontSizeHeader2);
                    line-height: 48px;
                    text-align: center;

                    span {
                        font-size: var(--fontSizeHeader3);
                        line-height: 27px;
                    }
                }

                .featured-image {
                    grid-row: 2;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                }

                p {
                    grid-row: 3;
                }
            }
        }
    }
}

@media(max-width: $tabletResolutionMinWidth) {
    .container {
        padding: 40px 0;

        &.bloated.featured {
            padding: 0;
        }

        & ~ & {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &:not(.bloated) .wrapper {
            padding: 0 25px;
            max-width: calc(100% - 50px);
        }

        &.full .wrapper {
            padding: 0;
            max-width: 100%;
            margin: 0;
        }

        &.featured .wrapper .text {
            h1,
            p,
            .flex {
                padding-right: 0;
            }
        }
    }
}

@media(max-width: $resolutionMd) {
    .container {
        &.full.featured {
            .wrapper {
                grid-template-columns: 1fr;
                padding: 0;

                .text {
                    grid-row: 2;
                    padding: 24px 25px;
                    margin: 0 auto;
                }

                .image {
                    &, img {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        position: static;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}