// Prelayout (needs to be imported before anything else)
@import 'reset';
@import 'fonts';
@import 'header';
@import 'footer';
@import 'flex';
@import 'grid';
@import 'container';
@import 'badge';
@import 'section';
@import 'icons';
@import 'ad';
@import 'aligned-image';
@import 'table';
@import 'form';
@import 'pill';
@import 'page-numbers';
@import 'topic';

html, body, #app, #layout {
    width: 100%;
    height: 100%;
}

body {
    font-size: var(--fontSizeParagraphMedium);
    color: var(--blueberry-100);
    font-family: 'Inter';
    font-weight: var(--fontWeightNormal);
    background: var(--cloud-40);
    line-height: 24px;
    text-rendering: optimizelegibility;
}

#app #layout {
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
    }
}

p.small {
    font-size: var(--fontSizeParagraphSmall);
}

a, a:link, a:hover, a:visited {
    color: var(--blueberry-100);
}

ul li {
    &.logo {
        background: url('../img/logo.svg') center no-repeat;
        object-fit: cover;
        background-size: 179px 27px;
        width: 179px;
        height: 27px;
    }

    &.logo-gn {
        background: url('../img/Greenudge-logo_hvitbak.png') center no-repeat;
        object-fit: cover;
        width: 90px;
        height: 90px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.fs-h1,
.fs-h2,
.fs-h3,
.fs-h4,
.fs-h5,
.fs-h6 {
    &, & a, & a:link, & a:hover, & a:visited {
        font-weight: var(--fontWeightNormal);
    }
}

h1, .fs-h1 {
    &, & a, & a:link, & a:hover, & a:visited {
        font-size: var(--fontSizeHeader1);
        line-height: 79px;

        &.hero {
            font-size: var(--fontSizeHero);
        }
    }
}

h2, .fs-h2 {
    &, & a, & a:link, & a:hover, & a:visited {
        font-size: var(--fontSizeHeader2);
        line-height: 48px;
    }
}

h3,
h4,
h5,
h6,
.fs-h3,
.fs-h4,
.fs-h5,
.fs-h6 {
    &, & a, & a:link, & a:hover, & a:visited {
        font-size: var(--fontSizeHeader3);
        line-height: 27px;
        text-decoration: none;
    }
}

.sr-only {
    position: absolute;
    left: -99999px;
    top: auto;
    overflow: hidden;
    height: 1px;
    width: 1px;
    color: transparent !important;
  
    &::-moz-selection {
        color: transparent !important;
    }
  
    &::selection {
        color: transparent !important;
    }
}

@media(max-width: $desktopResolutionMinWidth) {
    ul li {
        &.logo {
            background-size: 153px 21.68px;
            width: 153px;
            height: 21.68px;
        }

        &.logo-gn {
            background-size: 79px 79px;
            width: 79px;
            height: 79px;
        }
    }
}
  
@media(max-width: $extraSmallPhoneScreen) {
    ul li.logo {
        background-size: 99.45px 14.092px;
        width: 99.45px;
        height: 14.092px;
    }
}