.gn.gn-icons {
    display: inline-block;
    height: 24px;
    width: 24px;
    position: relative;

    &.hamburger {
        background: url('../img/icons/hamburger.svg') no-repeat;
        width: 30px;
        height: 25px;
        border-radius: 0;
        padding: 0;
    }

    &.cross {
        background: url('../img/icons/cross.svg') no-repeat;
        border-radius: 0;
        padding: 0;
    }

    &.arrow-up {
        background: url('../img/icons/arrow-up.svg') no-repeat;
        width: 8px;
        height: 14px;
    }

    &.chevron-up {
        background: url('../img/icons/chevron-up.svg') no-repeat;
        height: 14px;
        width: 16px;
    }

    &.chevron-down {
        background: url('../img/icons/chevron-down.svg') no-repeat;
        height: 14px;
        width: 16px;
    }

    &.magnifying-glass {
        background: url('../img/icons/magnifying-glass-solid.svg') no-repeat;
    }

    &.arrow-link {
        background: url('../img/icons/arrow-link.svg') no-repeat;
        height: 19px;
        width: 26px;
    }

    &.arrow-link-white {
        background: url('../img/icons/arrow-link-white.svg') no-repeat;
        height: 19px;
        width: 26px;
    }

    &.arrow-top {
        background: url('../img/icons/arrow-top.svg') no-repeat;
        height: 30px;
        width: 22px;
    }

    &.spinner {
        background: url('../img/icons/spinner-solid.svg') no-repeat;
        height: 30px;
        width: 30px;
        animation: rotation 1.1s linear infinite;
    }
}

@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}