.sections {
    padding: 80px 0;

    &.no-padding {
        padding: 0;
    }
}

a.section {
    display: block;

    &.category {
        text-decoration: none;

        h3 {
            font-size: var(--fontSizeHeader3);
            line-height: 27px;
        }
    }
}

.section {
    grid-column: span 6;
    background: var(--haze-100);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 40px 50px;

    &.dark {
        background: var(--cloud-80);
    }

    p.confirmations {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(2, auto);

        a {
            font-size: 27px;
        }
    }

    &.category {
        cursor: pointer;
    }

    &.full {
        grid-column: span 12;
    }

    &.sponsor {
        margin: 0 0 24px 0;
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-auto-rows: minmax(min-content, max-content);

        .image {
            width: 100%;
            height: 0;
            padding-bottom: 86.7%;
            object-fit: cover;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 10px;
            grid-column: span 2;
        }

        .details {
            grid-column: span 10;
            align-self: center;
            padding-left: 20px;

            &.full {
                grid-column: span 12;
            }

            p {
                padding-top: 10px;
                &:first-of-type {
                    padding-top: 0px;
                }
            }
        }

        &.dark {
            background-color: var(--cloud-80);

            .green-text {
                color: #3a8482;
            }

            .big-text {
                font-size: var(--fontSizeMobileMenu);
            }
        }

        &.link {
            cursor: pointer;

            .big-text {
                text-decoration: underline;
            }
        }

        &.m-0 {
            margin: 0;
        }
    }

    h2.section-hero {
        padding-top: 0;
    }

    p {
        padding-top: 50px;
        font-size: var(--fontSizeParagraphLarge);
        line-height: 27px;
    }
}