@import 'page';
@import 'post';
@import 'landing-prices';

.container {
    .sources {
        margin-top: 24px;
    }

    .table-of-contents {
        margin-top: 30px;
    }

    &.padding-60 {
        padding-top: 60px;
        padding-bottom: 60px;

        .sources {
            margin-top: 0;
        }
    }

    &.no-padding-bottom {
        padding-bottom: 0;
    }

    .pdf {
        margin-bottom: 30px;

        .header {
            margin-bottom: 30px;
            span {
                display: block;
                padding: 25px 30px;
            }
        }

        .header, img {
            cursor: pointer;
        }
    }

    .sources, .table-of-contents, .pdf .header {
        background: var(--haze-100);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        transition: 0.2s all ease-in-out;

        &:hover {
            background-color: var(--haze-80);
        }

        .top {
            width: 100%;
            padding: 45px 50px 0px 50px;
            cursor: pointer;
            background: url('../img/icons/chevron-up.svg') no-repeat;
            background-position: calc(100% - 50px) calc(100% - 7px);
            width: calc(100% - 100px);

            h2 {
                font-size: var(--fontSizeHeader3);
                padding-top: 0;
            }
        }
        
        .bottom {
            display: block;
            padding: 0 50px 45px 50px;

            ol, ul {
                margin-block-start: 0;
                margin-block-end: 0;
                margin-inline-start: 0;
                margin-inline-end: 0;
                padding-inline-start: 0;
                padding-inline-end: 0;
                padding-left: 25px;

                li {
                    &:not(:first-of-type) {
                        padding-top: 30px;
                    }

                    &, a, a:hover, a:visited, a:link {
                        font-size: var(--fontSizeParagraphSmall);
                        line-height: 22px;
                        word-wrap: break-word;
                    }

                    a, a:hover, a:visited, a:link {
                        color: var(--brocolli-80);
                        text-decoration: underline;
                        display: inline;
                    }
                }
            }
        }

        &.collapsed {
            .top {
                background-image: url('../img/icons/chevron-down.svg');
                background-position-y: center;
                padding-bottom: 45px;
            }

            .bottom {
                display: none;
            }
        }

        &.collapsed .bottom {
            display: none;
        }

        &.pdf .top {
            padding-bottom: 45px;
            background-position-y: center;
        }

        &.pdf {
            margin-bottom: 20px;
        }
    }
}

@media(max-width: $tabletResolutionMinWidth) {
    .container {
        .sources, .table-of-contents {
            margin-top: 60px;

            .top {
                width: calc(100% - 60px);
                background-position-x: calc(100% - 30px);
                padding: 23px 30px 0 30px;
            }

            .bottom {
                padding: 0 30px 23px 30px;
            }

            &.collapsed .top {
                padding-bottom: 23px;
            }
        }
    }
}