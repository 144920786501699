.topic {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--cloud-60);
    border-radius: 12px;
    margin: 86px 0;

    .btn {
        background-color: #41536F;
        color: #fff;
        border-width: 0;

        &:hover {
            background-color: #5E7598;
        }

        &:active {
            background-color: #8398B8;
        }
    
        .gn.gn-icons.arrow-link {
            background-image: url('../img/icons/arrow-link-white.svg');
        }
    }

    .content {
        align-self: center;
        padding: 24px 48px;

        h2, p {
            line-height: 140%;
        }

        h2 {
            padding-bottom: 16px;
            font-weight: var(--fontWeightNormal);
            font-size: var(--fontSizeHeader2);
        }

        p {
            padding-bottom: 24px;
            font-size: var(--fontSizeParagraphLarge);
        }
    }

    img {
        object-fit: cover;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        aspect-ratio: 16 / 9;
    }
    
    &.reversed img {
        order: 2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

@media(max-width: $resolutionMd) {
    .topic {
        grid-template-columns: 1fr;

        img {
            width: 100%;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 0;
        }

        &.reversed img {
            order: 0;
            border-top-left-radius: 12px;
            border-bottom-right-radius: 0;
        }
    }
}